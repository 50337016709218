import React from 'react';
import {
	ColumnContainer,
	ModalContainer,
	RowContainer,
} from '../global/containers';
import InfoDetails from '../global/infoText';
import { formatDate } from '../../util/util';
import { Alert, AlertTitle } from '@mui/material';

export default function RiderTransactionModal({
	modalOpen,
	toggleModal,
	data,
}) {
	return (
		<ModalContainer
			title="Rider Transaction"
			modalOpen={modalOpen}
			height="auto"
			width={{ xs: '80%', md: '50%', xl: '30%' }}
			type="in-actionable"
			handleCloseModal={toggleModal}
			handleCancelClick={toggleModal}
			handleActionClick={toggleModal}
		>
			<RowContainer
				justifyContent="space-between"
				padding="0px"
				marginBottom="0px"
				width="95%"
			>
				<ColumnContainer padding="0px" width="50" alignItems="left">
					<InfoDetails
						label={'Invoice Date'}
						value={
							data?.invoice?.date_created
								? formatDate(data?.invoice?.date_created)
								: '-'
						}
					/>
					<InfoDetails
						label={'Paired Station'}
						value={data?.issued_by_swapping_station_details?.name}
					/>
					<InfoDetails
						label={'Start Charge Level'}
						value={`${data?.issued_charge_percentage ?? '-'} %`}
					/>
					<InfoDetails
						label={'Total Energy Consumed'}
						value={`${data?.consumed_energy ?? '-'} %`}
					/>
				</ColumnContainer>
				<ColumnContainer width="50" alignItems="left">
					<InfoDetails
						label={'Battery SN'}
						value={data?.battery_details?.bat_sn}
					/>
					<InfoDetails
						label={'Returned Station'}
						value={data?.issued_by_swapping_station_details?.name}
					/>
					<InfoDetails
						label={'End Charge Level'}
						value={`${data?.received_charge_percentage ?? '-'} %`}
					/>
					<InfoDetails
						label={'Total Energy Bill'}
						value={`${data?.bill_amount ?? '-'} Ksh`}
					/>
				</ColumnContainer>
			</RowContainer>
			{data?.manual_swap_reason && (
				<RowContainer>
					<Alert severity="info" sx={{ width: '95%', margin: '20px auto' }}>
						<AlertTitle>Manual Swap</AlertTitle>
						This swap was performed manually. The reason given was "
						{data?.manual_swap_reason}"
					</Alert>
				</RowContainer>
			)}
			{data?.image_url && (
				<RowContainer
					justifyContent="center"
					padding="0px"
					marginBottom="0px"
					width="95%"
				>
					<img
						alt="swap-image"
						style={{ float: 'center' }}
						src={data?.image_url}
						height={'300'}
					/>
				</RowContainer>
			)}
		</ModalContainer>
	);
}
