import React, { useCallback, useState, useEffect } from 'react';
import { CardContainer } from '../global/containers';
import StationAttendantItem from '../global/stationAttendantItem';
import { FloatingActionButton } from '../global/button';
import { Add } from '@mui/icons-material';
import ViewStationAttendantModal from '../modals/viewStationAttendantModal';
import ConfirmationModal from '../modals/confirmationModal';
import AttendantModal from '../modals/attendantModal';
import {
	getChargeStationDetails,
	deleteChargeStationUser,
} from '../../services/chargeStationService';
import {
	getSwapStationDetails,
	deleteSwapStationUser,
} from '../../services/swapStation';
import CircularProgress from '@mui/material/CircularProgress';
import { EmptyOverlays } from '../global/overlays';
import { Box } from '@mui/material';

export default function SessionAttendantCard({ station }) {
	const [addModalOpen, setAddModalOpen] = useState(false);
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [viewAttendant, setViewAttendant] = useState(false);
	const [selectedAttendant, setSelectedAttendant] = useState(null);
	const [attendants, setAttendants] = useState([]);
	const [attendantUserTypes, setAttendantUserTypes] = useState({});
	const [loading, setLoading] = useState(false);

	const fetchAttendants = useCallback(() => {
		if (!station?.id || !station?.station_type) {
			console.error('Invalid station data:', station);
			return;
		}

		setLoading(true);
		const getStationDetails =
			station.station_type === 'Charging Station'
				? getChargeStationDetails
				: getSwapStationDetails;

		getStationDetails(station.id)
			.then((res) => {
				const fetchedAttendants = [];
				const userTypes = {};

				res?.station_users?.forEach((group) => {
					group.users.forEach((user) => {
						fetchedAttendants.push(user);
						userTypes[user.id] = group.user_type;
					});
				});

				setAttendants(fetchedAttendants);
				setAttendantUserTypes(userTypes);
			})
			.catch((error) => {
				console.error('Error fetching attendants:', error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [station]);

	useEffect(() => {
		fetchAttendants();
	}, [fetchAttendants]);

	const updateAttendants = useCallback(() => {
		fetchAttendants();
	}, [fetchAttendants]);

	const toggleViewAttendantModal = useCallback((attendant) => {
		setSelectedAttendant(attendant);
		setViewAttendant((prev) => !prev);
	}, []);

	const toggleDeleteModal = useCallback((attendant) => {
		setSelectedAttendant(attendant);
		setDeleteModal((prev) => !prev);
	}, []);

	const handleEditAttendant = useCallback((attendant) => {
		setSelectedAttendant(attendant);
		setEditModalOpen(true);
	}, []);

	const deleteUser = useCallback(
		(userType) => {
			if (
				userType === 'Charge Station Attendant' ||
				userType === 'Charge Station Franchisee'
			) {
				if (selectedAttendant) {
					deleteChargeStationUser(selectedAttendant.id)
						.then(() => {
							updateAttendants();
							setDeleteModal(false);
							setSelectedAttendant(null);
						})
						.catch((err) => {
							console.log('Error deleting attendant:', err);
						});
				}
			} else if (
				userType === 'Swap Station Attendant' ||
				userType === 'Swap Station Franchisee'
			) {
				if (selectedAttendant) {
					deleteSwapStationUser(selectedAttendant.id)
						.then(() => {
							updateAttendants();
							setDeleteModal(false);
							setSelectedAttendant(null);
						})
						.catch((err) => {
							console.log('Error deleting attendant:', err);
						});
				}
			} else {
				console.error('Invalid user type:', userType);
			}
		},
		[selectedAttendant, updateAttendants]
	);

	return (
		<CardContainer
			title={`Station Users (${attendants?.length})`}
			width={{ xs: '100%', lg: '50%' }}
			height="100%"
			hasActionableComponent={true}
			childrenContainerWidth="100%"
			titleActionSpacing="8px"
			actionableComponent={
				<FloatingActionButton
					icon={<Add color="secondary" />}
					onclick={() => {
						setAddModalOpen(true);
					}}
				/>
			}
		>
			<Box
				sx={{
					height: 'calc(100% - 60px)',
					overflowY: 'auto',
					padding: '10px',
				}}
			>
				{loading ? (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '100%',
						}}
					>
						<CircularProgress />
					</Box>
				) : attendants.length > 0 ? (
					attendants.map((attendant, index) => (
						<StationAttendantItem
							key={index}
							attendant={attendant}
							userType={attendantUserTypes[attendant.id]}
							viewAttendant={() => toggleViewAttendantModal(attendant)}
							editAttendant={() => handleEditAttendant(attendant)}
							deleteModal={() => toggleDeleteModal(attendant)}
						/>
					))
				) : (
					<EmptyOverlays message="This station does not have any attendants" />
				)}
			</Box>
			<ViewStationAttendantModal
				modalOpen={viewAttendant}
				toggleModal={() => setViewAttendant((prev) => !prev)}
				attendant={selectedAttendant}
				userType={
					selectedAttendant ? attendantUserTypes[selectedAttendant?.id] : ''
				}
			/>
			<ConfirmationModal
				modalOpen={deleteModal}
				toggleModal={() => setDeleteModal((prev) => !prev)}
				onConfirmation={() =>
					deleteUser(
						selectedAttendant ? attendantUserTypes[selectedAttendant.id] : ''
					)
				}
				itemType="Attendant"
				itemName={selectedAttendant?.name || 'Unknown Attendant'}
				confirmType={'delete'}
			/>
			<AttendantModal
				modalOpen={editModalOpen}
				closeModal={() => {
					setEditModalOpen(false);
				}}
				station={station}
				attendant={selectedAttendant}
				onSuccess={updateAttendants}
				isEditMode={true}
				userType={
					selectedAttendant ? attendantUserTypes[selectedAttendant.id] : ''
				}
			/>

			<AttendantModal
				modalOpen={addModalOpen}
				closeModal={() => {
					setAddModalOpen(false);
				}}
				station={station}
				onSuccess={updateAttendants}
				isEditMode={false}
			/>
		</CardContainer>
	);
}
